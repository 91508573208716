.gpt3__header{
    display: flex;
    flex-direction: row;
  
}

.gpt3__header-content{
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex:1
}

.gradient__text{

font-family: var(--font-family);
font-style: normal;
font-weight: 800;
font-size: 62px;
line-height: 75px;
letter-spacing: -0.04em;
background: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
margin-bottom: 2rem;
margin-top: 2rem;
}

.gpt3__header-content p {
   
    margin-bottom: 2rem;
   
    
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    
    color: #81AFDD;
    
}
.gpt3__header-content__input {
    width: 100%;
    margin: 2rem 0 1rem;

    display: flex;
    flex-direction: row;
}
.gpt3__header-content__input input{
    flex: 2;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: var(--color-footer);
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: #fff;

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.gpt3__header-content__input button{
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: #FF4820;
    border: 2px solid #FF4820;
    padding: 0 1rem;
    color: #fff;
    cursor: pointer;
    outline: none;

    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    
}
.gpt3__header-content__input input text{
    width: 174px;
height: 27px;


font-family: var(--font-family);
font-weight: 400;
font-size: 20px;
line-height: 27px;

color: #3D6184;
}

.gpt3__header-content__people{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}
.gpt3__header-content__people p{
    flex: 2;
margin: 0  0.5rem;
font-family: 'Manrope';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 38px;
/* identical to box height, or 313% */


color: #FFFFFF;
}

.gpt3__header-image video{
 
   flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.gpt3__header-image video {
        padding-left: 2rem;
        width: 750px;;
        height: 500px;
}

@media screen and (max-width: 1050px) {
    .gpt3__header {
        flex-direction: column;
    }

    .gpt3__header-content {
        margin: 0 0 3rem;
    }
    .gpt3__header-image video {
      
        width:100%;
        height: 100%;
}
}
@media screen and (max-width: 650px) {
    .gpt3__header-content h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .gpt3__header-content p {
        font-size: 16px;
        line-height: 24px;
    }
    
    .gpt3__header-content__people {
        flex-direction: column;
    }

    .gpt3__header-content__people p {
        margin: 0;
    }

    .gpt3__header-content__input input,
    .gpt3__header-content__input button {
        font-size: 16px;
        line-height: 24px;
    }
    .gpt3__header-image video {
      
        width:100%;
        height: 100%;
}
}

@media screen and (max-width: 490px) {
    .gpt3__header-content h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .gpt3__header-content p {
        font-size: 14px;
        line-height: 24px;
    }

    .gpt3__header-content__input input,
    .gpt3__header-content__input button {
        font-size: 12px;
        line-height: 16px;
    }
    .gpt3__header-image video {
      
        width:100%;
        height: 100%;
}
}
