.gpt3__possibility{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.gpt3__possibility-image{

   flex: 2;
    display: flex;
   justify-content: flex-start;
   align-items: center;
   margin-right: 2rem;
}

.gpt3__possibility-image img{
    width: 100%;
    height: 100%;
}

.gpt3__possibility-content{
    flex:1;
    display: flex;
    font-family: 'Manrope';
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;

}

.gpt3__possibility-content button{
    font-style: normal;
margin:2rem 0 ;
font-family: var(--font-family);
font-weight: 400;
font-size: 20px;
line-height: 28px;
background: #FF4820;
border: 2px solid #FF4820;
padding: 0 1rem;
color: #fff;
cursor: pointer;
outline: none;
border-radius:5px ;


}
.gpt3__possibility-content h4{
    font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 30px;
margin:2rem 0 ;


color: #71E5FF;
}

.gpt3__possibility-content p{
    font-family: 'Manrope';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 30px;
/* or 188% */

margin-bottom: 2rem;
color: #81AFDD;
margin: 2rem 0;
}

.gpt3__possibility-content h4:last-child {
    color: #ff8A71;
}
.gpt3__possibility-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 34px;
    line-height: 45px;
    margin: 1rem 0;
    margin: 1rem 0;
}
@media screen and (max-width: 950px) {
    .gpt3__possibility {
        flex-direction: column;
    }

    .gpt3__possibility-image {
        margin: 1rem 0;
    }

    .gpt3__possibility-image img {
        width: unset;
        height: unset;
    }

    .gpt3__possibility-content {
        margin-top: 2rem;
    }
}

@media screen and (max-width: 700px) {
    .gpt3__possibility-image img {
        width: 100%;
        height: 100%;
    }
}

.thish1{
    margin-left: 5rem;
}
.gpt3__possibility-icons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
}

.gpt3__possibility__Ind{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1rem 0.5rem;
}

.gpt3__possibility__Ind img{
    width: 100%;
    height: 100%;
}