.gpt3__cta{
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
   padding: 3rem;
    margin : 2rem 6rem;
    background: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
        border-radius: 5px;
}

.gpt3__cta-content p{
    
font-family: 'Manrope';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 30px;
/* or 250% */


color: #0E0E0E;
}

.gpt3__cta-content h3{
    font-family: 'Manrope';
font-style: normal;
font-weight: 800;
font-size: 24px;
line-height: 45px;
/* or 188% */


color: #000000;
}

.gpt3__cta-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 2rem;
}
.gpt3__cta-btn button{
    background: #000000;
border-radius: 40px;
font-family: 'Manrope';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 30px;
/* identical to box height, or 167% */

padding: 0.5rem 1rem;
color: #FFFFFF;
}

@media screen and (max-width: 650px) {
    .gpt3__cta {
        flex-direction: column;
    }

    .gpt3__cta-btn {
        margin: 2rem 0 0;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__cta {
        flex-direction: column;
        margin: 4rem 2rem;
    }

    .gpt3__cta-content h3 {
        font-size: 18px;
        line-height: 32px;
    }

    .gpt3__cta-btn button {
        font-size: 14px;
        line-height: 28px;
    }
}
