.gpt3__brand {
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: center;
align-items: center;
}

.gpt3__brand img{
 padding: 0 2rem;
}

@media screen and (max-width:1050px) {
    .gpt3__brand{
        flex-direction: column;
        
    }
    .gpt3__brand img{
        padding: 0.5rem 0;
    }
}